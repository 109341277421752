


import { Component, Vue } from 'vue-property-decorator';
import EwButton from '@/_modules/standalone-company/components/UI/Ew-Button/Ew-Button.vue';
import IconEwVideoCall from '@/_modules/icons/components/icon-ew-video-call.vue';
import { TimeStatus } from '@/_types/time-status.enum';
import EventHelper from '@/_helpers/event.helper';
import { Getter } from 'vuex-class';
import { TEvent } from '@/_types/event.type';
import eventDiscoveryService, { TEventDiscoveryServiceConfig } from '@/_services/event-discovery.service.ts';
import { TContact } from '@/_types/contact.type';
import { NavigationGuard, NavigationGuardNext } from 'vue-router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TPromoPageAttachedContacts } from '@/_types/promo-page/promo-page-attached-contacts.type';
import { TPromoPage } from '@/_types/promo-page/promo-page.type';
import { TCompanyVideoChatState } from '@/_modules/meeting-rooms/types/company-video-chat-state.type';
// @ts-ignore
import Statistics from '@/services/statistics.js';

const beforeRouteEnter: { to: any; from: any } = { to: null, from: null };
const LAUNCHER_IMAGE_SRC: string = require('@/assets/images/videoChatRoomLauncherImage.png');

@Component({
  components: {
    EwButton,
    IconEwVideoCall
  }
})
export default class VideoChatRoomLauncher extends Vue {
  @Getter('meetingRoomsStore/getCompanyVideoChatStateByExternalId') getCompanyVideoChatStateByExternalId: (externalId: string) => TCompanyVideoChatState;
  @Getter('_eventStore/event') event: TEvent;
  @Getter('promoPageStore/contact') contactInfo: TContact;
  @Getter('promoStore/promoPageByExternalId') promoPageByExternalId: any;

  private destroyed$: Subject<void> = new Subject<void>();
  public isEDServiceConnected: boolean = false;

  public get launcherImageSrc(): string {
    return LAUNCHER_IMAGE_SRC || '';
  }

  public get isContactTeamMember(): boolean {
    const contactId = this.contactId;
    const attachedContacts = this.attachedContacts;
    if (!contactId || !attachedContacts || !attachedContacts.length) {
      return false;
    }
    return !!(attachedContacts.find(c => c.contact.id === contactId));
  }

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get externalId(): string {
    return this.$route.params.external_id ? this.$route.params.external_id : null;
  }

  public get contactId(): number {
    return (this.contactInfo && this.contactInfo.id) || null;
  }

  public get videoChatState(): TCompanyVideoChatState {
    return this.getCompanyVideoChatStateByExternalId(this.externalId);
  }

  public get company(): TPromoPage {
    return this.promoPageByExternalId(this.externalId);
  }

  public get attachedContacts(): TPromoPageAttachedContacts[] {
    const company = this.company;
    return (company && company.attached_contacts) || [];
  }

  public get videoChatRoomId(): string {
    const eventId = this.eventId ? this.eventId.toFixed(0) : '0';
    return 'company-video-chat-' + eventId + '-' + this.externalId;
  }

  public mounted(): void {
    this.connectToEventDiscoveryService();
  }

  public connectToEventDiscoveryService(): void {
    if (!eventDiscoveryService.isConnected()) {
      eventDiscoveryService.configure({
        eventId: this.eventId,
        contactId: this.contactId,
      });
    }

    eventDiscoveryService.connected$.pipe(
      takeUntil(this.destroyed$),
    ).subscribe(this.onEDServiceConnectChange.bind(this));
  }

  public beforeRouteEnter(to: NavigationGuard, from: NavigationGuard, next: NavigationGuardNext): void {
    beforeRouteEnter.to = to;
    beforeRouteEnter.from = from;
    next();
  }

  public get videoChatRoomLauncherButtonText(): string {
    if (this.isAbleToStartChatRoom) {
      return this.$t('button.launch_video_chat_room') as string;
    }
    return this.$t('button.join_to_video_chat_room') as string;
  }

  public get isEventEnabled(): boolean {
    return !!(this.event && this.event.is_enabled);
  }

  public get isAbleToStartChatRoom(): boolean {
    return this.isEventEnabled
      && !this.isVideoChatActive(this.videoChatState)
      && this.isContactTeamMember;
  }

  public get isAbleToJoinVideoChatRoom(): boolean {
    return this.isVideoChatActive(this.videoChatState);
  }

  public get isButtonDisabled(): boolean {
    return !this.isAbleToStartChatRoom && !this.isAbleToJoinVideoChatRoom;
  }

  public isVideoChatActive(videoChatState: TCompanyVideoChatState): boolean {
    const currentTimestamp = Math.floor((new Date()).getTime() * 0.001);
    return eventDiscoveryService.isConnected()
      && !!videoChatState
      && videoChatState.expire > currentTimestamp
      && videoChatState.isPublished
      && !!videoChatState.participants[0];
  }

  public onEDServiceConnectChange(config: TEventDiscoveryServiceConfig): void {
    this.isEDServiceConnected = !!config;
    if (this.isEDServiceConnected) {
      this.subscribeToVideoChatRoomStateUpdates();
    }
  }

  public subscribeToVideoChatRoomStateUpdates(): void {
    const topic = this.videoChatRoomId;
    eventDiscoveryService.subscribe(topic);
  }

  public unsubscribeFromVideoChatRoomStateUpdates(): void {
    const topic = this.videoChatRoomId;
    eventDiscoveryService.unsubscribe(topic);
  }

  public onVideoChatRoomButtonClick(): void {
    if (this.isButtonDisabled) {
      return;
    }

    const contactId = this.contactId;
    const videoChatState = this.videoChatState;
    const isContactTeamMember = this.isContactTeamMember;

    const eventTimeStatus = EventHelper.getEventTimeStatus(this.event);

    if (eventTimeStatus === TimeStatus.PAST || eventTimeStatus === TimeStatus.FUTURE) {
      this.$store.dispatch('_eventStore/setIsChatRoomTimeCheckDialogVisible', true);
      return;
    }

    if (!videoChatState && isContactTeamMember) {
      const meetingRoomConfig = {
        type: 'company',
        eventId: this.eventId,
        contactId: contactId,
        moderatorContactId: contactId,
        externalId: this.externalId,
      };
      this.$store.dispatch('meetingRoomsStore/join', meetingRoomConfig);

      Statistics.companyVideoChatRoomView({
        eventId: this.eventId,
        contactId: contactId,
        externalId: this.externalId,
        isRoomCreated: true,
      }, beforeRouteEnter);

    } else if (videoChatState) {
      const meetingRoomConfig = {
        type: 'company',
        eventId: this.eventId,
        contactId: contactId,
        moderatorContactId: videoChatState.moderatorContactId,
        externalId: this.externalId,
      };
      this.$store.dispatch('meetingRoomsStore/join', meetingRoomConfig);

      Statistics.companyVideoChatRoomView({
        eventId: this.eventId,
        contactId,
        externalId: this.externalId,
      }, beforeRouteEnter);

    }
  }
}
